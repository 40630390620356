@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "colors.scss";

.red-banner {
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: $red;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.image-gallery-bullets {
    bottom: -20px;
    button {
        width: 78px;
        padding: 2px !important;
        border-radius: 4px !important;
        box-shadow: none !important;
        background-color: #291515 !important;
        opacity: 0.2;
        transform: none !important;
        &.active {
            background-color: $red !important;
            opacity: 1;
        }
    }
}

.image-gallery-image {
    border-radius: 8px;
    border: 4px solid #FFF;
    max-width: 1189px;
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    background-color: #FFF;
    height: 500px;
    background-size: cover;
    background-position: center;
    text-align: left;
    box-sizing: border-box;
    // box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
    .gallery-text {
        max-width: 480px;
        padding: 30px;
        .subtitle {
            color: #FFF;
            font-size: 24px;
            font-weight: 600;
            line-height: 20px;
            white-space: normal;
            margin: 0;
            margin-bottom: 20px;
            font-family: 'TungstenRnd', sans-serif;
        }
        .title {
            color: $red;
            font-size: 120px;
            font-size: 120px;
            line-height: 100px;
            white-space: normal;
            margin: 0;
            margin-bottom: 40px;
            font-family: 'TungstenRnd', sans-serif;
        }
        button {
            font-family: 'TungstenRnd', sans-serif;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0.69px;
            border-radius: 16px;
            padding-left: 50px;
            padding-right: 50px;
        }
    }
    @media screen and (max-width: 980px ) {
        margin-top: 0px;
        .gallery-text {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 30px 55px;
            .title {
                font-size: 50px;
                line-height: 50px;
            }
        }
    } 
}

.gallery-nav {
    background-color: #000;
    width: 48px;
    height: 48px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &.left {
        left: 0px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    &.right {
        right: 0px;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
}

.home-section {
    background-color: rgba(235, 237, 240, 0.2);
    border-top: 1px solid $very_light_blue;
    border-bottom: 1px solid $very_light_blue;
    padding: 20px 0px;
    margin-top: 120px;
    position: relative;
    .title {
        background-color: $black;
        color: #FFF;
        position: absolute;
        top: -27px;
        left: 0;
        // width: calc(calc(calc(100% - 1189px) / 2) + 202px - 44px);
        // min-width: 220px;
        width: 375px;
        min-width: 375px;
        text-align: right;
        padding: 16px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        &.blue {
            background-color: $duck_egg_blue;
            color: $black;
        }
        // &.title2 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 266px - 44px);
        //         min-width: 285px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 266px - 44px);
        //         min-width: 285px;
        //     }
        // }
        // &.title3 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 260px - 44px);
        //         min-width: 270px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 250px - 44px);
        //         min-width: 270px;
        //     }
        // }
        // &.title4 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 175px - 44px);
        //         min-width: 175px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 185px - 44px);
        //         min-width: 185px;
        //     }
        // }
        // &.title5 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 220px - 44px);
        //         min-width: 240px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 220px - 44px);
        //         min-width: 240px;
        //     }
        // }
        // &.title6 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 150px - 44px);
        //         min-width: 150px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 150px - 44px);
        //         min-width: 150px;
        //     }
        // }
        // &.title7 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 220px - 44px);
        //         min-width: 220px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 250px - 44px);
        //         min-width: 250px;
        //     }
        // }
        // &.title8 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 330px - 44px);
        //         min-width: 265px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 340px - 44px);
        //         min-width: 265px;
        //     }
        // }
        // &.title9 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 190px - 44px);
        //         min-width: 190px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 190px - 44px);
        //         min-width: 190px;
        //     }
        // }
        // &.title11 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 130px - 44px);
        //         min-width: 130px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 130px - 44px);
        //         min-width: 130px;
        //     }
        // }
        // &.title12 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 100px - 44px);
        //         min-width: 100px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 100px - 44px);
        //         min-width: 100px;
        //     }
        // }
        // &.title13 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 180px - 44px);
        //         min-width: 180px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 180px - 44px);
        //         min-width: 180px;
        //     }
        // }
        // &.title14 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 250px - 44px);
        //         min-width: 250px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 250px - 44px);
        //         min-width: 250px;
        //     }
        // }
        // &.title15 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 160px - 44px);
        //         min-width: 160px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 160px - 44px);
        //         min-width: 160px;
        //     }
        // }
        // &.title16 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 200px - 44px);
        //         min-width: 200px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 225px - 44px);
        //         min-width: 225px;
        //     }
        // }
        // &.title17 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 200px - 44px);
        //         min-width: 200px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 225px - 44px);
        //         min-width: 225px;
        //     }
        // }
        // &.title18 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 200px - 44px);
        //         min-width: 200px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 200px - 44px);
        //         min-width: 200px;
        //     }
        // }
        // &.title19 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 200px - 44px);
        //         min-width: 200px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 200px - 44px);
        //         min-width: 200px;
        //     }
        // }
        // &.title20 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 170px - 44px);
        //         min-width: 170px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 170px - 44px);
        //         min-width: 170px;
        //     }
        // }
        // &.title21 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 240px - 44px);
        //         min-width: 240px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 240px - 44px);
        //         min-width: 240px;
        //     }
        // }
        // &.title22 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 140px - 44px);
        //         min-width: 140px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 140px - 44px);
        //         min-width: 140px;
        //     }
        // }
        // &.title23 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 120px - 44px);
        //         min-width: 120px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 120px - 44px);
        //         min-width: 120px;
        //     }
        // }
        // &.title24 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 160px - 44px);
        //         min-width: 160px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 160px - 44px);
        //         min-width: 160px;
        //     }
        // }
        // &.title25 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 275px - 44px);
        //         min-width: 275px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 275px - 44px);
        //         min-width: 275px;
        //     }
        // }
        // &.title26 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 220px - 44px);
        //         min-width: 220px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 220px - 44px);
        //         min-width: 220px;
        //     }
        // }
        // &.title27 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 270px - 44px);
        //         min-width: 270px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 270px - 44px);
        //         min-width: 270px;
        //     }
        // }
        // &.title28 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 160px - 44px);
        //         min-width: 170px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 170px - 44px);
        //         min-width: 170px;
        //     }
        // }
        // &.title29 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 100px - 44px);
        //         min-width: 100px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 110px - 44px);
        //         min-width: 100px;
        //     }
        // }
        // &.title31 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 150px - 44px);
        //         min-width: 150px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 150px - 44px);
        //         min-width: 150px;
        //     }
        // }
        // &.title32 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 217px - 44px);
        //         min-width: 217px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 217px - 44px);
        //         min-width: 217px;
        //     }
        // }
        // &.title33 {
        //     &.en {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 247px - 44px);
        //         min-width: 247px;
        //     }
        //     &.pt {
        //         width: calc(calc(calc(100% - 1189px) / 2) + 247px - 44px);
        //         min-width: 247px;
        //     }
        // }
    }
    @media screen and (max-width: 980px) {
        .title{
            width: 315px;
            min-width: 315px;
        }
        h3 {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 600px) {
        .title{
            width: 215px;
            min-width: 215px;
        }
        h3 {
            width: 200px;
        }
    }
    .right-arrow {
        background-color: $black;
        position: absolute;
        top: -27px;
        right: 0;
        width: 48px;
        height: 48px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        cursor: pointer;
        user-select: none;
        &.blue {
            background-color: $duck_egg_blue;
        }
        a {
            color: #FFF;
            text-decoration: none;
        }
    }
    .prods-container {
        // overflow: auto;
        white-space: nowrap;
        margin-top: 30px;
        .product {
            display: inline-block;
            width: 220px;
            margin: 10px;
            cursor: pointer;
            transition: all 0.3s;
            padding: 10px 10px 0px 10px;
            box-sizing: border-box;
            border: solid 1px $very_light_blue;
            border-radius: 6px;
            position: relative;
            vertical-align:top;
            &:first-of-type {
                margin-left: 30px;//calc(calc(100% - 1189px) / 2);
            }
            img {
                width: 100%;
            }
            .prod-img {
                height: 200px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            h3 {
                font-size: 16px;
                font-weight: 400;
                color: $black;
                line-height: 1.5;
                white-space: initial;
                min-height: 48px;
            }
            .price-tag {
                position: relative;
                img {
                    width: 60px;
                }
                p {
                    position: absolute;
                    top: 0;
                    left: 15px;
                    color: #FFF;
                    font-weight: 700;
                    line-height: 20px;
                    font-size: 12px;
                    letter-spacing: 0.6px;
                }
            }
            .original-price {
                font-weight: 600;
                color: $warm_grey;
                letter-spacing: 0.6px;
                font-size: 12px;
            }
            .buy {
                padding: 10px;
                background-color: #FFF;
                color: $red;
                font-family: 'TungstenRnd', sans-serif;
                font-weight: 700;
                letter-spacing: 0.6px;
                font-size: 18px;
                text-align: center;
                border-top: 1px solid $very_light_blue;
                width: 100%;
                margin-left: -10px;
                margin-top: 10px;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                transition: all 0.3s;
            }
            .buy-quantity {
                display: none;
                background-color: #FFF;
                color: $red;
                font-family: 'TungstenRnd', sans-serif;
                font-weight: 700;
                letter-spacing: 0.6px;
                font-size: 18px;
                text-align: center;
                border-top: 1px solid $very_light_blue;
                width: 100%;
                margin-left: -10px;
                margin-top: 10px;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                user-select: none;
                .quantity {
                    color: #000;
                    font-size: 18px;
                    font-weight: 600;
                }
                .minus, .plus {
                    background-color: $red;
                    padding: 10px;
                    width: 30px;
                    img {
                        width: 20px;
                    }
                }
                .minus {
                    border-bottom-left-radius: 6px;
                }
                .plus {
                    border-bottom-right-radius: 6px;
                    margin-right: -20px;
                }
            }
            &:hover {
                border: 1px solid $warm_grey;
                border-radius: 6px;
                .buy {
                    display: none;
                }
                .buy-quantity {
                    display: flex;
                }
            }
            .favorite {
                position: absolute;
                right: 10px;
                top: 10px;
                width: 32px;
                cursor: pointer;
            }
        }
        .collection {
            display: inline-block;
            margin: 10px;
            width: 346px;
            height: 150px;
            box-shadow:inset 0 0 0 350px rgba(0, 0, 0, 0.3);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: all 0.3s;
            &:first-of-type {
                margin-left: 30px;//calc(calc(100% - 1189px) / 2);
            }
            &:hover {
                box-shadow:inset 0 0 0 350px rgba(0, 0, 0, 0);
            }
            div {
                padding: 7px 15px;
                background-color: #FFF;
                text-align: center;
                width: fit-content;
                margin: 0 auto;
                border-radius: 30px;
                margin-top: 100px;
                h3 {
                    font-size: 16px;
                    font-weight: 600;
                    color: $black;
                    white-space: initial;
                }
            }
            @media screen and (max-width: 980px) {
                width: 160px;
                &:first-of-type {
                    margin-left: 30px;
                }
            }
        }
        .shoppinglist {
            width: 217px;
            margin: 10px;
            box-sizing: border-box;
            border: solid 1px $very_light_blue;
            border-radius: 6px;
            position: relative;
            vertical-align:top;
            background-color: #FFF;
            position: relative;
            cursor: pointer;
            .images {
                text-align: center;
                width: 100%;
                &>div {
                    border-radius: 6px;
                    width: calc(50% - 7.5px);
                    margin: 5px 0px;
                    text-align: center;
                    align-self: flex-start;
                    &:nth-of-type(odd) {
                        margin-right: 5px;
                        margin-left: 5px;
                    }
                    .bgimg {
                        width: 90%;
                        height: 100px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                    img {
                        width: 90%;
                    }
                }
            }
            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,0.5);
                justify-content: flex-end;
                div {
                    background-color: #FFF;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 14px;
                    border-bottom: 1px solid $very_light_blue;
                    div {
                        padding: 10px 0px;
                    }
                    &:last-of-type {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.history-bg {
    width: 100%;
    height: 80px;
    background-size: cover;
    border-radius: 6px;
    margin-top: 80px;
    @media screen and (max-width: 768px) {
        height: auto;
    }
    cursor: pointer;
    .content {
        padding: 20px;
        box-sizing: border-box;
        background-color: rgba(0,0,0,0.8);
        height: 100%;
        border-radius: 6px;
    }
}

.support-card {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.32);
    border-left: 4px solid $cherry_red;
    border-radius: 12px;
    padding: 18px;
    cursor: pointer;
    flex: 1;
    user-select: none;
    @media screen and (max-width: 820px) {
        margin-right: 0px;
        margin-top: 10px;
        width: 100%;
        max-width: 100%;
        flex: 1 1 auto;
    }
    &.maxw {
        max-width: 346px;
        margin-right: 20px;
        &:last-of-type {
            margin-right: 0px;
        }
        @media screen and (max-width: 820px) {
            margin-right: 0px;
            margin-top: 10px;
            width: 100%;
            max-width: 100%;
            flex: 1 1 auto;
        }
    }
    h4 {
        color: $warm_grey;
        font-size: 14px;
        font-weight: 400;
    }
    h3 {
        color: $warm_grey;
        font-size: 16px;
        font-weight: 400;
        color: $black;
    }
    .flex1 {
        margin-left: 20px;
    }
    .supp-img {
        width: 48px;
    }
    @media screen and (max-width: 420px) {
        &:first-of-type {
            margin-right: 0px !important;
        }
    }
}
.feedback-card {
    cursor: default;
}

.offers-banner {
    background-color: $black;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    .back-btn {
        // margin-left: -40px;
        // @media screen and (max-width: 1270px) {
        //     margin-left: -10px;
        // }
    }
    .container {
        padding-top: 50px;
        padding-bottom: 30px;
    }
    h2 {
        color: #FFF;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
        font-family: 'TungstenRnd', sans-serif;
    }
    h1 {
        color: $cherry_red;
        font-size: 120px;
        line-height: 100px;
        font-weight: 900;
        max-width: 480px;
        font-family: 'TungstenRnd', sans-serif;
    }
    button {
        font-family: 'TungstenRnd', sans-serif;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.69px;
    }
}

.text-section {
    margin: 100px 0px;
    h2 {
        color: $black;
        font-size: 28px;
        font-weight: 600;
        font-family: 'TungstenRnd', sans-serif;
    }
    h1 {
        color: $cherry_red;
        font-size: 42px;
        font-weight: 900;
        letter-spacing: 1.31px;
        font-family: 'TungstenRnd', sans-serif;
        margin-bottom: 40px;
    }
    p {
        color: $black;
        line-height: 1.86;
    }
    .flex1 {
        &:first-of-type {
            margin-right: 100px;
        }
    }
}

.Calendar {
    position: absolute;
    button {
        color: $black;
    }
    .force-btn {
        border-radius: 5px;
        outline: none;
        border: 1px solid $red;
        font-weight: 600;
        font-size: 14px;
        transition: all 0.3s;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid $red;
        background-color: #FFF;
        color: $cherry_red;
        &:hover {
            background-color: $red;
            color: #FFF;
            border: 1px solid #FFF;
        }
    }
}
.Calendar__sectionWrapper {
    min-height: 22em;
}