@import "colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700;900&display=swap');

@font-face {
    font-family: TungstenRnd;
    font-weight: 900;
    src: url("./font/TungstenRnd-Bold.otf") format("opentype");
}
@font-face {
    font-family: TungstenRnd;
    font-weight: 600;
    src: url("./font/TungstenRnd-Semibold.otf") format("opentype");
}
@font-face {
    font-family: TungstenRnd;
    font-weight: 500;
    src: url("./font/TungstenRnd-Medium.otf") format("opentype");
}
@font-face {
    font-family: TungstenRnd;
    font-weight: 400;
    src: url("./font/TungstenRnd-Light.otf") format("opentype");
}

html {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
body {
    position:relative;
    overflow-x: hidden;
    background-color: #FFF;
    min-height: 100vh;
    font-family: 'Fira Sans', sans-serif;
    font-weight: normal;
}

.container {
    max-width: 1189px;
    margin: 0 auto;
    @media screen and (max-width: 1189px) {
        padding: 0px 30px;
    }
    .container {
        padding: 0px;
    }
}

.container-small {
    max-width: 730px;
    margin: 0 auto;
    @media screen and (max-width: 1189px) {
        padding: 0px 30px;
    }
}

p, h4, h3, h2, h1 {
    margin: 0;
}

p {
    font-size: 14px;
}

.charcoal {
    color: $charcoal_grey !important;
}
.warm_grey {
    color: $warm_grey !important;
}
.black {
    color: $black !important;
}
.white {
    color: #FFF;
}
.red {
    color: $red;
}
.duck-bg {
    background-color: $duck_egg_blue;
}
.charcoal-bg {
    background-color: $charcoal_grey !important;
}

.flex {
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &.column {
        flex-direction: column;
    }
    &.between {
        justify-content: space-between;
    }
    &.around {
        justify-content: space-around;
    }
    &.center {
        justify-content: center;
    }
    &.start {
        justify-content: flex-start;
    }
    &.end {
        justify-content: flex-end;
    }
    &.align-start {
        align-items: flex-start;
    }
    &.align-end {
        align-items: flex-end;
    }
    &.align-center {
        align-items: center;
    }
    .flex1 {
        flex: 1;
    }
}

.f400 {
    font-weight: 400 !important;
}

.f600 {
    font-weight: 600 !important;
}

.f700 {
    font-weight: 700;
}

.f900 {
    font-weight: bold;
}

.size13 {
    font-size: 13px !important;
}

.size14 {
    font-size: 14px !important;
}

.size16 {
    font-size: 16px !important;
}

.size18 {
    font-size: 18px !important;
}

.size20 {
    font-size: 20px !important;
}

.pointer {
    cursor: pointer;
}

.underline {
    text-decoration: underline !important;
    &:hover {
        text-decoration: underline;
    }
}

.uppercase {
    text-transform: uppercase;
}

button {
    border-radius: 5px;
    outline: none;
    border: 1px solid $red;
    font-weight: 600;
    font-size: 16px;
    color: #FFF;
    background-color: $red;
    transition: all 0.3s;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid $red;
    &:hover {
        &:not(.nohover) {
            background-color: #FFF;
            color: $cherry_red;
        }
    }
    &.white {
        background-color: #FFF;
        color: $cherry_red;
        &:hover {
            background-color: $red;
            color: #FFF;
            border: 1px solid #FFF;
        }
    }
    &.white-border {
        border: 1px solid #FFF;
    }
    &.black {
        background-color: $black;
        color: #FFF;
        border: 1px solid #FFF;
        &:hover {
            background-color: #FFF;
            color: $black;
        }
    }
    &.smaller {
        font-size: 14px;
    }
    &:disabled {
        opacity: 0.4;
        cursor: default;
        color: #FFF !important;
        background-color: $red !important;
        &.white {
            background-color: #FFF !important;
            color: $cherry_red !important;
            border: 1px solid $red !important;
        }
        &.custom-disable {
            opacity: 1;
            background-color: #5e0d10 !important;
            border: 1px solid #5e0d10 !important;
        }
    }
}

select{
    white-space: normal;
    padding: 10px 20px;
    background-color: #fff;
    border: 1px solid #e01f26;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    color: #e01f26;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='red' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 5px;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.separator {
    border-top: 1px solid $very_light_blue;
    width: 100%;
    margin: 24px 0px;
}

.text16 {
    font-size: 16px;
}

.custom-inp {
    border: 1px solid $very_light_blue;
    border-radius: 6px;
    padding: 22px 16px;
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    transition: all 0.3s;
    font-size: 14px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $warm_grey;
        opacity: 1; /* Firefox */
    }
      
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $warm_grey;
    }
      
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: $warm_grey;
    }
    &:focus {
        outline: none;
        // border: 1px solid $red;
    }
}

.rdt_TableHead {
    &>div {
        background-color: rgba(143, 137, 137, 0.1);
    }
    div {
        font-size: 14px;
        color: $warm_grey;
    }
}

.upload-area {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 32px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    margin-top: 40px;
}

.add-more-product-area {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
    color: #8f8989;
    background-color: rgba(184, 16, 15, 0.05);
}

.autocompleteList{
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    div{
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
        font-size: 14px;
        &:hover{
            background-color: #e9e9e9;
        }
    }
}

.buttons-wrap {
    button {
        margin-bottom: 20px;
        @media screen and (max-width: 980px) {
            &:first-of-type {
                margin-right: 0px !important;
            }
        }
    }
}

.line-through {
    text-decoration: line-through;
}

.popup-msg {
    position: fixed;
    z-index: 10;
    bottom: 20px;
    left: 20px;
    background-color: $cherry_red;
    color: #FFF;
    font-weight: 600;
    padding: 28px 20px;
    border-radius: 6px;
    img {
        margin-left: 20px;
    }
}

.loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    top: 0;
    left: 0;
    background-color: $red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loading-img {
        width: 100%;
        max-width: 375px;
    }
    .bar {
        margin-top: 20px;
        width: 160px;
        background-color: rgba(255,255,255,0.2);
        transition: all 0.3s;
        height: 2px;
        border-radius: 2px;
        .bar-inner {
            background-color: #FFF;
            animation: progressBar 3s ease-in-out;
            animation-fill-mode:both;
            height: 100%;
        }
    }
}

.breadcumbLink{
    color: #8f8989;
    text-decoration: none;
}

.product{
    .unitCard{
        position: absolute;
        right: 0;
        bottom: 40px;
        width: 30px;
        padding: 5px 10px 5px 10px;
        img{
            width: 100%;
        }
    }
    .unitCardOn{
        background-color: $red;
        cursor: pointer;
    }
    .unitCardOff{
        background-color: rgba(235,237,240,.2);
    }
    .price-tag-b p {
        color: #291515 !important;
    }
}

@keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
}