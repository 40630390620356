@import 'colors.scss';

.header {
    background-color: $red;
    height: 79px;
    position: fixed;
    top:0;
    width: 100%;
    z-index: 10;
    .logo {
        width: 110px;
        margin-right: 70px;
    }
    a {
        text-decoration: none;
    }
    .header-item {
        padding: 30px 20px;
        cursor: pointer;
        position: relative;
        @media screen and (max-width: 1300px) and (min-width: 992px) {
            padding: 30px 9px;
        }
        img {
            width: 19px;
            &.arrow_r_hover {
                display: none;
            }
        }
        h3 {
            font-weight: 500;
            color: #FFF;
            letter-spacing: 0.4px;
            transition: all 0.3s;
            margin: 0;
            font-size: 16px;
            &.active {
                color: $cherry_red !important;
            }
            @media screen and (max-width: 1300px) and (min-width: 992px) {
                font-size: 14px;
            }
        }
        .to-expand {
            position: absolute;
            z-index: 2;
            top: 79px;
            left: 0px;
            background-color: #FFF;
            width: 220px;
            display: none;
            p {
                padding: 20px;
                color: $warm_grey !important;
                font-size: 16px;
                font-weight: 500;
                border-bottom: 1px solid $duck_egg_blue;
                transition: all 0.3s;
                &.logout {
                    font-size: 14px;
                    text-align: right;
                    text-decoration: underline;
                }
                &:hover {
                    color: $black !important;
                }
                &.active{
                    color: $black !important;
                }
            }
        }
        &:hover {
            background-color: #FFF;
            .to-expand {
                display: block;
            }
            h3 {
                color: $cherry_red
            }
            .arrow_r_hover {
                display: block;
            }
            .arrow_w, .arrow_r {
                display: none;
            }
        }
        &.active {
            background-color: #FFF;
            h3 {
                color: $cherry_red;
            }
        }
        &:hover + .to-expand-mobile{
            display: block;
        }
    }
    &.black {
        background-color: $black;
        .header-item {
            transition: all 0.3s;
            &:hover {
                background-color: #FFF;
                h3 {
                    color: $black;
                }
            }
            &.active {
                background-color: #FFF;
                h3 {
                    color: $black;
                }
            }
        }
    }
    .header-user {
        &>div, &>img {
            cursor: pointer;
        }
        img {
            width: 30px;
        }
        p {
            font-size: 16px;
            letter-spacing: 0.4px;
            font-weight: 500;
            color: #FFF;
            transition: all 0.3s;
            @media screen and (max-width: 1300px) and (min-width: 992px) {
                font-size: 14px;
            }
        }
        .my-search {
            padding: 30px 17px;
            margin-top: 0px;
            position: relative;
            .search-w {
                width: 20px;
            }
            .search-r {
                display: none;
                width: 20px;
            }
            .to-expand{
                width: 550px;
                left: auto;
                right: 0;
                max-height: 300px;
                min-height: 50px;
                overflow-y: auto;
                padding: 16px 10px 16px 10px;
                border-bottom: 1px solid #ebedf0;
                p{
                    padding: 10px 20px 10px 20px;
                    font-size: 14px;
                }
            }
            .searchbar {
                text-align: right;
                input{
                    position: static;
                    width: 85%;
                    display: block;
                    margin: 0 auto;
                    right: 0;
                    top: -44px;
                    outline: none;
                    border: 1px solid #ebedf0;
                    border-radius: 6px;
                    padding: 16px 16px 16px 40px;
                    color: #291515;
                    font-family: "Fira Sans",sans-serif;
                    background-color: #fff;
                    background-repeat: no-repeat;
                    background-position: 10px;
                    background-size: 20px 20px;
                }
                .searchResults{
                    width: 95%;
                    margin: 0 auto;
                    display: block;
                    box-shadow: none;
                    position: static;
                    background-color: #fff;
                    z-index: 1;
                    right: 0;
                    top: 6px;
                    border-radius: 6px;
                    max-height: 300px;
                    overflow-y: auto;
                    text-align: left;
                }
            }
            &.active {
                p {
                    color: $cherry_red;
                }
                .search-w {
                    display: none;
                }
                .search-r {
                    display: block;
                }
            }
            &:hover {
                background-color: #FFF;
                p {
                    color: $cherry_red;
                }
                .search-w {
                    display: none;
                }
                .search-r {
                    display: block;
                }
            }
        }
        .my-profile {
            padding: 25px 17px;
            margin-top: -6px;
            .user-r {
                display: none;
            }
            &.active {
                p {
                    color: $cherry_red;
                }
                .user-w {
                    display: none;
                }
                .user-r {
                    display: block;
                }
            }
            &:hover {
                background-color: #FFF;
                p {
                    color: $cherry_red;
                }
                .user-w {
                    display: none;
                }
                .user-r {
                    display: block;
                }
            }
        }
        .my-cart {
            padding: 25px 17px;
            margin-top: -6px;
            position: relative;
            height: 34px;
            .has-items{
                position: absolute;
                top: 20px;
                right: 5px;
                width: 20px;
                height: 20px;
                background-color: #fff;
                border-radius: 10px;
                text-align: center;
                font-size: 12px;
                line-height: 20px;
                color: $cherry_red;
            }
            .cart-r {
                display: none;
            }
            .cart {
                display: none;
                position: absolute;
                z-index: 10;
                top: 84px;
                right: 0px;
                background-color: #FFF;
                width: 100vw;
                max-width: 710px;
                cursor: default;
                .cart-header {
                    padding: 60px 40px 20px 40px;
                    font-weight: 500;
                    font-size: 18px;
                    border-bottom: 1px solid $very_light_blue;
                }
                .cart-items {
                    max-height: 200px;
                    overflow-y: scroll;
                    .cart-item {
                        padding: 24px 40px;
                        border-bottom: 1px solid $very_light_blue;
                        .cart-img {
                            width: 64px;
                            height: 64px;
                            background-color: $duck_egg_blue;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;
                            border-top-right-radius: 6px;
                            border-bottom-right-radius: 6px;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                            img {
                                width: 90%;
                            }
                        }
                        h3 {
                            color: $black;
                            font-size: 16px;
                            font-weight: 400;
                            margin-bottom: 5px;
                        }
                        p {
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                        .more-options {
                            width: 100%;
                            text-align: right;
                            color: $warm_grey;
                            cursor: pointer;
                            font-size: 20px;
                        }
                        .cart-quantities {
                            width: 100px;
                            border-radius: 6px;
                            border: 1px solid $warm_grey;
                            padding: 5px;
                            margin-top: 10px;
                            img {
                                width: 24px;
                                cursor: pointer;
                            }
                            p {
                                color: $black;
                                font-size: 14px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
                .actions {
                    padding: 25px 40px;
                    background-color: #FBFBFD;
                    border-bottom: 1px solid $very_light_blue;
                    p {
                        font-size: 14px;
                        font-weight: 500;
                    }
                    img {
                        width: 25px;
                        margin-right: 10px;
                    }
                    &>div:first-of-type {
                        margin-bottom: 10px;
                    }
                }
            }
            &:hover {
                background-color: #FFF;
                .has-items{
                    background-color: $cherry_red;
                    color: #fff;
                }
                .cart-w {
                    display: none;
                }
                .cart-r {
                    display: block;
                }
                .cart {
                    display: block;
                }
            }
        }
    }
    .mobileMenu{
        width: 100vw;
        height: 100vh;
        background-color: rgb(255, 255, 255);
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: auto;
        .menuLine{
            padding: 20px;
            font-weight: 600;
            border-bottom: 1px solid $very_light_blue;
            h3{
                color: #000;
                font-size: 16px;
                font-weight: 600;
            }
            &:hover + .to-expand-mobile{
                display: flex;
            }
        }
        .to-expand-mobile {
            position: static;
            display: none;
            width: 100%;
            padding: 20px;
            border-bottom: 1px solid #ebedf0;
            background-color: rgba(235,237,240,.2);
            p{
                color: #8f8989;
                font-size: 16px;
                font-weight: 600;
                margin: 0 10px;
                cursor: pointer;
            }
            &:hover {
                display: flex;
            }
        }
        .active {
            color: $cherry_red !important;
            h3{
                color: $cherry_red;
            }
        }
        .has-items{
            width: 20px;
            height: 20px;
            color: #fff;
            border-radius: 10px;
            text-align: center;
            font-size: 12px;
            line-height: 20px;
            background-color: $cherry_red;
        }
        .cart-items {
            overflow-y: auto;
            position: relative;
            .cart-item {
                padding: 24px 40px;
                border-bottom: 1px solid $very_light_blue;
                .cart-img {
                    width: 64px;
                    height: 64px;
                    background-color: $duck_egg_blue;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    img {
                        width: 90%;
                    }
                }
                h3 {
                    color: $black;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 14px;
                    margin-bottom: 5px;
                }
                .more-options {
                    width: 100%;
                    text-align: right;
                    color: $warm_grey;
                    cursor: pointer;
                    font-size: 20px;
                }
                .cart-quantities {
                    width: 100px;
                    border-radius: 6px;
                    border: 1px solid $warm_grey;
                    padding: 5px;
                    margin-top: 10px;
                    img {
                        width: 24px;
                        cursor: pointer;
                    }
                    p {
                        color: $black;
                        font-size: 14px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .actions{
            padding: 25px 40px;
            background-color: #fbfbfd;
            border-bottom: 1px solid #ebedf0;
        }
        
    }
    @media screen and (max-width: 980px) {
        height: auto;
        .container {
            padding: 20px 30px;
        }
        .logo {
            width: 90px;
        }
    }
}

.back-wrap {
    padding: 40px 0px 20px 0px;
    border-bottom: 1px solid $very_light_blue;
    img {
        cursor: pointer;
    }
    &>div {
        max-width: 1189px;
        margin: 0 auto;
    }
    p {
        margin-left: 24px;
        font-size: 18px;
        font-weight: 500;
    }
    @media screen and (max-width: 1200px) {
        padding: 40px 20px 20px 20px;
    }
}

.breadcrumb {
    color: $warm_grey;
    margin-top: 18px;
}

.footer {
    padding: 20px 0px;
    border-top: 1px solid $very_light_blue;
    color: $warm_grey;
    font-size: 12px;
    margin-top: 100px;
    a {
        color: $warm_grey;
        text-decoration: none;
        margin-left: 10px;
    }
    @media screen and (max-width: 1189px) {
        padding: 20px 30px;
    }
}