@import 'colors.scss';

.profile-page {
    margin-top: 80px;
    min-height: calc(100vh - 454px);
    .profile-img {
        width: 100px;
        margin-right: 20px;
    }

    .notif {
        padding: 30px;
        border: 1px solid $very_light_blue;
        border-radius: 6px;
        background-color: #FFF;
        margin: 20px 0px;
        position: relative;
        .notif-icon {
            margin-right: 40px;
        }
        .notif-close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
        .read-dot {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $red;
            top: 5px;
            left: 5px;
        }
    }

    .services {
        margin-top: 60px;
        .service {
            text-align: center;
            background-color: #FFF;
            border-radius: 6px;
            padding: 10px;
            border-bottom: 4px solid $red;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.32);
            flex-grow: 1;
            width: calc(33% - 50px);
            box-sizing: border-box;
            margin: 0px 80px 20px 0px;
            cursor: pointer;
            &:nth-child(3n+3) {
                @media screen and (min-width: 1059px) {
                    margin: 0px 0px 20px 0px;
                }
            }
            @media screen and (max-width: 820px) {
                margin-right: 0px;
                margin-top: 10px;
                width: 100%;
                max-width: 100%;
                flex: 1 1 auto;
            }
        }
    }

    .lang-wrap {
        margin-left: 20px;
        position: relative;
        user-select: none;
        .lang-open {
            position: absolute;
            background-color: #FFF;
            border-radius: 6px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.32);
            box-sizing: border-box;
            top: 30px;
            padding: 5px 0px;
            width: 150px;
            p {
                padding: 10px;
                border-bottom: 1px solid $very_light_blue;
                transition: all 0.3s;
                &:hover {
                    color: $red;
                }
                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }    
    
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FFF;
        -webkit-transition: .4s;
        transition: .4s;
        border:1px solid $very_light_blue;
        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 3px;
            background-color: $red;
            -webkit-transition: .4s;
            transition: .4s;
        }
        &.round {
            border-radius: 34px;
            &:before {
                border-radius: 50%;
            }
        }
    }
        
    input:checked + .slider {
        background-color: #FFF;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px #FFF;
    }
    
    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .input-wrap {
        margin-bottom: 30px;
        p {
            margin-top: 10px;
        }
        &:first-of-type {
            margin-right: 20px;
        }
        @media screen and (max-width: 980px) {
            margin-right: 0px !important;
            min-width: 200px;
        }
    }

    .weekday {
        background-color: #F4F6F7;
        padding: 6px 16px;
        color: $warm_grey;
        font-weight: 600;
        margin-right: 10px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 2px;
        border: 1px solid transparent;
        transition: all 0.3s;
        &:last-of-type {
            margin-right: 0px;
        }
        &.active {
            border: 1px solid $duck_egg_blue;
            background-color: rgba(214, 238, 230, 0.4);
            color: #3e524b;
        }
    }

    .changed-banner {
        background-size: cover;
        background-position: center;
        .shadow {
            padding: 80px 20px;
            background-color: rgba(0,0,0,0.7);
            height: 100%;
            width: 100%;
        }
        h2 {
            font-size: 32px;
            font-family: TungstenRnd;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .changed-block {
            background-color: #FFF;
            border-radius: 6px;
            margin-top: 20px;
            padding: 18px 0px;
            max-width: 467px;
            box-sizing: border-box;
            &>.flex {
                padding: 10px 24px;
                border-bottom: 1px solid $very_light_blue;
                &:first-of-type {
                    padding-top: 0px;
                }
                &:last-of-type {
                    padding-bottom: 0px;
                    border-bottom: none;
                }
            }
        }
    }
    .clients {
        margin-top: 30px;
        .client {
            img {
                border-radius: 6px;
                width: 40px;
            }
        }
    }
    .months {
        width: 24px;
        height: 24px;
        line-height: 24px;
        color: $black;
        font-weight: 600;
        background-color: $warm_grey;
        border-radius: 50%;
        text-align: center;
        margin: 0px 5px;
    }
    .business-tabs {
        p {
            cursor: pointer;
            &.active {
                color: $black !important;
            }
        }
        .separator-v {
            margin: 0px 10px;
            width: 1px;
            height: 30px;
            background-color: #CCC;
        }
    }
    .business-prod {
        margin: 10px;
        width: 217px;
        position: relative;
        .product {
            background-color: $duck_egg_blue;
            margin: 0px;
            padding: 10px;
            width: 100%;
        }
        h3 {
            font-size: 16px;
            font-weight: 400;
            color: $black;
            line-height: 1.5;
            white-space: initial;
        }
        .ranking {
            background-color: #1c2925;
            width: 28px;
            height: 28px;
            line-height: 28px;
            color: $duck_egg_blue;
            font-weight: 600;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            top: 12px;
            left: 12px;
        }
    }
}

.register-page {
    margin-top: 80px;
    @media screen and (max-width: 980px) {
        margin-top: 30px;
    }
    &.nomarg {
        margin-top: 0px;
    }
    .filter-drop {
        margin-right: 20px;
        position: relative;
        &:last-of-type {
            margin-right: 0px;
        }
        .dropdown {
            border: 1px solid $very_light_blue;
            border-radius: 6px;
            padding: 19px 16px;
            width: 100%;
            margin-top: 10px;
            box-sizing: border-box;
            transition: all 0.3s;
            cursor: pointer;
            .ptrigger {
                padding: 16px;
                user-select: none;
            }
            img {
                padding-right: 16px;
            }
        }
        .drop-menu {
            position: absolute;
            background-color: #FFF;
            width: 100%;
            z-index: 1;
            border-radius: 6px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.32);
            p {
                color: $warm_grey;
                padding: 10px;
                border-bottom: 1px solid $very_light_blue;
                cursor: pointer;
                transition: all 0.3s;
                &:last-of-type {
                    border-bottom: none;
                }
                &:hover {
                    color: $black;
                }
            }
        }
    }

    .ordernow-bg {
        width: 100%;
        background-size: cover;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: pointer;
        background-position: center;
        .content {
            padding: 60px 20px 60px 20px;
            box-sizing: border-box;
            background-color: rgba(0,0,0,0.8);
            height: 100%;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            @media screen and (max-width: 980px) {
                padding: 60px 0px;
            }
        }
    }

    .register-title {
        font-family: 'TungstenRnd', sans-serif;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .prods-container {
        margin-top: 20px;
        .collection {
            margin: 10px;
            flex: 1;
            height: 150px;
            box-shadow:inset 0 0 0 350px rgba(0, 0, 0, 0.3);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                box-shadow:inset 0 0 0 350px rgba(0, 0, 0, 0);
            }
            div {
                padding: 7px 15px;
                background-color: #FFF;
                text-align: center;
                width: fit-content;
                margin: 0 auto;
                border-radius: 30px;
                margin-top: 100px;
                h3 {
                    font-size: 16px;
                    font-weight: 600;
                    color: $black;
                    white-space: initial;
                }
            }
        }
        .shoppinglist {
            width: 217px;
            margin: 10px;
            box-sizing: border-box;
            border: solid 1px $very_light_blue;
            border-radius: 6px;
            position: relative;
            vertical-align:top;
            background-color: #FFF;
            position: relative;
            .images {
                text-align: center;
                width: 100%;
                &>div {
                    border-radius: 6px;
                    width: calc(50% - 7.5px);
                    margin: 5px 0px;
                    text-align: center;
                    align-self: flex-start;
                    &:first-of-type, &:nth-of-type(3) {
                        margin-right: 5px;
                        margin-left: 5px;
                    }
                    .bgimg {
                        width: 90%;
                        height: 100px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                    img {
                        width: 90%;
                    }
                }
            }
            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,0.5);
                justify-content: flex-end;
                div {
                    background-color: #FFF;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 14px;
                    border-bottom: 1px solid $very_light_blue;
                    div {
                        padding: 10px 0px;
                    }
                    &:last-of-type {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .login-input {
        padding: 10px;
        background-color: rgba(255,255,255,0.1);
        width: 100%;
        box-sizing: border-box;
        color: #FFF;
        font-size: 14px;
        font-family: 'Fira Sans', sans-serif;
        outline: none;
        border-right: none;
    }
}

.feedback-page {
    min-height: 0;
    .input-wrap {
        &:first-of-type {
            margin-right: 0px;
        }
        textarea{
            height: 150px;
        }
    }
    .countCharsContainer{
        text-align: right;
        margin: -38px 10px 0 0;
    }
}

.complaint-page{
    .countCharsContainer{
        text-align: right;
        margin: -28px 20px 0 0;
    }
}

.errorForm{
    border: 1px solid #e01f26 !important;
}